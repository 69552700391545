import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Marketing Podcast: the marketing mingle with websuasion"
        ogDescription="A marketing podcast covering strategy, technology tips and conversation with marketing executives, branding specialists, and other professionals from the marketing world."
        image="/images/podcast/the-marketing-mingle-with-websuasion.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Marketing Podcast:<br /> <span className="websuasion">the marketing mingle<br />with websuasion</span></h1>
						<h4><a href="/content-marketing-strategy/">Strategy</a>, <a href="/marketing-technology/">technology</a> tips and conversation with marketing executives, branding specialists, and other professionals from the marketing world.</h4>
		        <p>
							Twice monthly join <span className="websuasion">websuasion</span> president, J. Ryan Williams, for "the marketing mingle". This podcast is dedicated to uncovering the latest in marketing strategy and technology. Twice a month, Ryan invites leading <a href="/fractional-marketing-team/">marketing professionals</a> to the table to share their insights, stories, and expert advice. Whether you're part of a marketing firm, a solo consultant, or a marketing executive, tune in to learn and get exposed to new ideas.
						</p>
						<p>
							<span className="websuasion">the marketing mingle with websuasion</span> is available on your favorite podcast platform including: <a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479">Apple Podcasts</a> and <a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG">Spotify</a>.
            </p>
          </div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/the-marketing-mingle-with-websuasion.jpg" className="rounded-square" alt="Marketing Podcast: the marketing mingle with websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Subscribe To Our Marketing Podcast</h2>
							<p>
								We are available on the most popular podcasting platforms. Just click on the links below and the show should pop up in your favorite podcasting app. And please leave us a review! That's the best way to help us spread the word. Thank you!
							</p>
							<p>
								<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank">
									<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" />
								</a>
								<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank">
									<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" />
								</a>
							</p>
							<p>
								Podcast RSS feed:<br /><br />

								<span className="red">https://websuasion.com/podcast.xml</span>
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div className="double-frame-block">
	            <h3><a href="/podcast/season-one/">Websuasion Conversation: Season One</a></h3>
	            <p>Season one concluded in June of 2020. We filmed in person at our previous facility at Pinewood Atlanta Studios and the format was more like a executive/business owner profile of each guest.<br /><br /><a href="/podcast/season-one/">Listen to Season One</a></p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
				<TextBlock
	        textPadded
	        textLeft={
	          <div>
				      <div className='player-wrapper'>
				        <ReactPlayer
				          className='react-player'
				          url='https://www.youtube.com/watch?v=-YTR647HTBM'
				          width='100%'
				          height='100%'
				        />
							</div>
	          </div>
	        }
	        textRight={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>Websuasion combines data analytics, cutting-edge tech, and expert teams. "Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	          </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
